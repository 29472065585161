import request from '@/utils/request';
import { CommonListRes, CommonRes, Page } from './common';
import { RobotEntity } from './robot';

export interface GetWorkListReq {
    page: number;
    limit: number;
    companyType?: string;
    startTimeBegin?: string;
    startTimeEnd?: string;
    robotName?: string;
    taskResult?: string;
    stopTimeBegin?: string;
    stopTimeEnd?: string;
    mapName?: string;
    taskName?: string;
}
export interface GetWorkReq {
    id: number;
    companyType: string;
}
/**
 * 任务的结构体
 */
export interface Work {
    id: number;
    reportName:string;
    robotId: string;
    taskName: string;
    picture: string;
    taskType: number;
    taskResult: number;
    taskDuration: number;
    taskArea: number;
    startTime: string;
    stopTime: string;
    mapName: string;
    longitude: string;
    latitude: string;
    completionRate: number;
    robotEntity: RobotEntity;
}
export interface GetNamesListReq {
    robotIds?: string;
    mapNames?: string;
}
export interface GetWorkByIdReq {
    id: number;
}

export interface GetWorkByIdRes {
    msg: string;
    code: number;
    task: Work;
}

export interface GetNamesRes {
    msg: string;
    code: number;
    data: string[];
}

/**
 * 搜索任务列表
 *
 * @export
 * @returns {Promise<CommonListRes<Page<Work>>>}
 */
 export function getWorkList(params: GetWorkListReq): Promise<CommonListRes<Page<Work>>> {
    return request.get<CommonListRes<Page<Work>>>('/task/search', params);
}

/**
 * 根据任务id获取任务
 *
 * @export
 * @returns {Promise<GetWorkByIdRes>}
 */
 export function getWorkById(params: GetWorkByIdReq): Promise<GetWorkByIdRes> {
    return request.get<GetWorkByIdRes>('/task/'+params.id, null);
}

/**
 * 获取所有任务名
 *
 * @export
 * @returns {Promise<GetNamesRes>}
 */
 export function getTaskNames(params: GetNamesListReq): Promise<GetNamesRes> {
    return request.get<GetNamesRes>('/task/names', params);
}

/**
 * 获取所有地图名
 *
 * @export
 * @returns {Promise<GetNamesRes>}
 */
 export function getMapNames(params: GetNamesListReq): Promise<GetNamesRes> {
    return request.get<GetNamesRes>('/task/mapNames', params);
}

/**
 * 导出任务
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function exportTask(params: GetWorkListReq): Promise<CommonRes> {
    return request.post2<CommonRes>('/task/export', params);
}
/**
 * 导出单个任务
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
export function exportSigleTask(params: GetWorkReq): Promise<CommonRes> {
    return request.post2<CommonRes>('/task/export/single', params);
}