




















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class FloatButton extends Vue {
  @Prop({
    type: Number,
    default: 150,
  })
  private itemWidth!: number; // 悬浮按钮宽度
  @Prop({
    type: Number,
    default: 40,
  })
  private itemHeight!: number; // 悬浮按钮高度
  @Prop({
    type: Number,
    default: 0,
  })
  private gapWidth!: number; // 距离左右两边距离
  @Prop({
    type: Number,
    default: 0.8,
  })
  private coefficientHeight!: number; // 从上到下距离比例
  private clientWidth: number = 0;
  private clientHeight: number = 0;
  private timer: any = null;
  private currentTop: number = 0;
  private left: number = 0;
  private top: number = 0;
  private startX: number = 0;
  private startY: number = 0;

  created() {
    this.clientWidth = document.documentElement.clientWidth;
    this.clientHeight = document.documentElement.clientHeight;
    this.left = this.clientWidth - this.itemWidth - this.gapWidth;
    this.top = this.clientHeight * this.coefficientHeight;
  }

  //滑动开始
  private touchStart(e: any) {
    console.log("touchStart", e);
    if (e.targetTouches.length === 1) {
      let touch = e.targetTouches[0];
      this.startX = touch.clientX;
      this.startY = touch.clientY;
    }
    e.preventDefault();
  }
  //滑动移动
  private touchMove(e: any) {
    console.log("touchMove", e);
    if (e.targetTouches.length === 1) {
      // 一根手指
      let touch = e.targetTouches[0];
      this.left = touch.clientX - this.itemWidth / 2;
      this.top = touch.clientY - this.itemHeight / 2;
    }
    e.preventDefault();
  }
  //滑动结束
  private touchEnd(e: any) {
    console.log("touchEnd", e);
    if (e.changedTouches.length === 1) {
      let touch = e.changedTouches[0];
      let endX = touch.clientX;
      let endY = touch.clientY;
      if (
        Math.abs(endX - this.startX) < 5 &&
        Math.abs(endY - this.startY) < 5
      ) {
        this.$emit("onFloatBtnClicked");
        return;
      }
    }

    if (this.left - (this.clientWidth - this.itemWidth - this.gapWidth) > 0) {
      this.left = this.clientWidth - this.itemWidth - this.gapWidth;
    }
    if (this.left < 0) {
      this.left = 0;
    }
    if (this.top < 0) {
      this.top = 0;
    }
    if (
      this.top >
      this.clientHeight * this.coefficientHeight + this.itemHeight
    ) {
      this.top = this.clientHeight * this.coefficientHeight;
    }
    e.preventDefault();
  }
  // 点击
  private onclick() {
    this.$emit("onFloatBtnClicked");
  }
  private handleScrollStart() {
    this.timer && clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.handleScrollEnd();
    }, 10);
    this.currentTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (this.left > this.clientWidth / 2) {
      this.left = this.clientWidth - this.itemWidth / 2;
    } else {
      this.left = -this.itemWidth / 2;
    }
  }

  private handleScrollEnd() {
    let scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop === this.currentTop) {
      if (this.left > this.clientWidth / 2) {
        this.left = this.clientWidth - this.itemWidth - this.gapWidth;
      } else {
        this.left = this.gapWidth;
      }
      clearTimeout(this.timer);
    }
  }

  beforeDestroy() {
    // 添加监听页面滚动
    // window.removeEventListener("scroll", this.handleScrollStart);
  }
  destroyed() {
    console.log("destroyed");
  }
}
