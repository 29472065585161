



































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Work } from "@/api/work";
import { formatsecd2Text } from "@/utils/utils";
@Component({
  filters: {
    dealStatusColorFilter(val: string) {
      switch (val) {
        case "1":
          return "itemValueBlue";
        case "2":
          return "itemValueYellow";
        case "3":
          return "itemValueRed";
        default:
          return "itemValueRed";
      }
    },
  },
})
export default class WorkBoard extends Vue {
  @Prop({
    type: Object,
    default: {
      id: 1,
      name: "",
      seriesNumber: "",
    },
  })
  private item!: Work;
  @Prop({
    type: Number,
    default: 0,
  })
  private index!: number; //显示返回按钮

  private skip(e: any) {
    let id = this.item.id;
    this.$emit(
      "clickItem",
      this.item.robotEntity.robotId,
      this.item.robotEntity.name,
       id,
    );
  }
  private setText(e: any) {
    return formatsecd2Text(e)
  }
}
